import {
  Box,
  Button,
  Container,
  Flex,
  Link,
  useColorMode,
  Text,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

import ContactFooter from "../components/ContactFooter";
import ImgTextModule from "../components/ImgTextModule";
import IntroComponent2colData from "../components/IntroComponent2colData";
import IntroComponent2colMonochrome from "../components/IntroComponent2colMonochrome";
import QuadKachelnInvRelations from "../components/QuadKachelnInvRelations";
import FadeInAnimation from "../DefinitionComponents/Animation-Components/FadeInAnimation";
import GImage from "../DefinitionComponents/Utility/GImage";

export default function InvestorRelations() {
  const { setColorMode } = useColorMode();

  useEffect(() => {
    setColorMode("light");
  });

  const data = useStaticQuery(graphql`
    query getDownloads {
      allWpDownload(sort: { order: ASC, fields: date }) {
        nodes {
          downloadTitle
          pdfdownload {
            filename
            localFile {
              absolutePath
              relativePath
            }
          }
        }
      }
    }
  `);

  const DOWNLOADS = data.allWpDownload.nodes;
  return (
    <>
      <Helmet>
        <title>tokentus | Investor Relations</title>
        <meta
          name="description"
          content="Information about us. As a stock listed company in Germany we are transparent. And also legally bound to disclose information about us."
        />
        <link rel="canonical" href="https:/tokentus.com/investorrelations" />
      </Helmet>
      <ImgTextModule
        src={"Investors-relations.mp4"}
        delay={0.3}
        duration={0.5}
        threshold={0.3}
        initialY={10}
        initialX={10}
        video={true}
        h="95vh"
        mediaOpacity={0.9}
        bgColor="brand.black"
      >
        <Container variant="layoutContainer" mt={8}>
          <Box p={{ base: 0, lg: "8" }}>
            <FadeInAnimation
              threshold={0.4}
              delay={0.4}
              duration={0.7}
              initialX={0}
              initialY={-10}
            >
              <Text color="white" fontWeight="normal" fontSize="xl">
                Information about us
              </Text>
            </FadeInAnimation>
            <FadeInAnimation
              threshold={0.4}
              delay={0.4}
              duration={0.7}
              initialX={0}
              initialY={-10}
            >
              <Text
                color="brand.white"
                fontWeight="normal"
                fontSize={{ base: "5xl", lg: "6xl" }}
              >
                Investor Relations.
              </Text>
            </FadeInAnimation>

            <FadeInAnimation
              threshold={0.4}
              delay={1}
              duration={0.7}
              initialX={0}
              initialY={10}
            >
              <Text
                color="brand.white"
                mb={24}
                maxW={{ base: "100%", lg: "50%" }}
                fontSize="2xl"
              >
                We are transparent. And also legally bound to disclose
                information about us.{" "}
              </Text>
            </FadeInAnimation>
          </Box>
        </Container>
      </ImgTextModule>

      <IntroComponent2colData
        headline="Basic Data."
        variant="thirds"
        text1="Stock exchange"
        text3="Data"
        text2="<small>Börsensegment<br> 
        Handelsplätze<br>
        International Securities Identification Number (ISIN)<br>
        Wertpapierkennnummer (WKN)<br>	
        Börsenkürzel<br>
        Aktienart<br>	
        Grundkapital in Stück Aktien<br>	
        Anzahl der Aktien<br>	
        Handelsaufnahme<br>	
        Emissionsexperte<br>
        Geschäftsjahr<br>	
        Rechnungslegung</small>	"
        text4="<small>
        m:access (Freiverkehr) der Börse München<br>
        XETRA, Frankfurt, München, Berlin, gettex<br>
        DE000A3CN9R8<br>
       	A3CN9R<br>
           	14D
        
               <br>	auf den Namen lautende nennwertlose Stückaktien
        
               <br>	 8.430.750,00
        
               <br>	 8.430.750
        
               <br>	03.08.2021
        
               <br>	mwb fairtrade Wertpapierhandelsbank AG
        
               <br>	31.12.<br>
       
               HGB</small>"
      />

      <IntroComponent2colMonochrome
        version="thirds"
        headline="Downloads."
        text1="Information and transparency for you."
        text2="Legal transparency is part of our DNA. "
      />

      <Container variant={"layoutContainer"}>
        {DOWNLOADS.map((Download) => (
          <Flex
            flexWrap="wrap"
            backgroundColor={"brand.lightgray"}
            px={12}
            py={8}
            mb={4}
          >
            <Text
              minW={{ base: "50%", lg: "80%" }}
              pt={2}
              mb={4}
              color="brand.darkmedgray"
              fontWeight="normal"
              fontSize="lg"
            >
              {Download.downloadTitle}
            </Text>
            <a href={`/${Download.pdfdownload.localFile.relativePath}`}>
              <Button
                color="white"
                size={"lg"}
                fontWeight={"normal"}
                px={6}
                backgroundColor="brand.meddarkgray"
                leftIcon={
                  <GImage w="14px" h="14px" src="download-document.svg" />
                }
              >
                PDF Download
              </Button>
            </a>
          </Flex>
        ))}
      </Container>

      <QuadKachelnInvRelations />
      <Container variant={"layoutContainer"} mb={0.5}>
        <ContactFooter
          title="Are you ready ?"
          subtitle={" This is a special time. For special people."}
          name="Sarah Michel, COO"
          email="sarah.michel@tokentus.com"
          phone="+49 177 24 21 383"
        />
      </Container>

      {/* <IntroComponent2colMonochrome
        version="thirds"
        headline="Our Team"
        text1="We strive and drive."
        text2="Get to know us."
      />
      <TeamQuadLink /> */}
    </>
  );
}
